
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import axios from "axios";

export default defineComponent({
  name: "app",

  setup() {
    const store = useStore();
    onMounted(async () => {
     
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      await store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
