import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/course/voluntary",
        name: "voluntary",
        component: () => import("@/views/voluntary/Voluntary.vue"),
      },
      {
        path: "/course/voluntary/:id",
        name: "voluntary-detail",
        component: () => import("@/views/voluntary/VoluntaryDetail.vue"),
      },
      {
        path: "/course/voluntary-learn/:id",
        name: "voluntary-learn",
        component: () => import("@/views/voluntary/VoluntaryLearn.vue"),
      },
      {
        path: "/course/voluntary-exam/:cId/:secId/:chId/:exId/:type",
        name: "voluntary-exam",
        component: () => import("@/views/voluntary/VoluntaryExam.vue"),
      },
      {
        path: "/course/mandatory-list/:id",
        name: "mandatory-list",
        component: () => import("@/views/mandatory/MandatoryList.vue"),
      },
      // {
      //   path: "/course/mandatory-list/:id",
      //   name: "mandatory-list",
      //   component: () => import("@/views/mandatory/MandatoryList.vue")
      // },
      {
        path: "/course/mandatory-detail/:sbId/:cId",
        name: "mandatory-detail",
        component: () => import("@/views/mandatory/MandatoryDetail.vue"),
      },
      {
        path: "/course/mandatory-learn/:sbId/:cId",
        name: "mandatory-learn",
        component: () => import("@/views/mandatory/MandatoryLearn.vue"),
      },
      {
        path: "/course/mandatory-exam/:sbId/:cId/:secId/:chId/:exId/:type",
        name: "mandatory-exam",
        component: () => import("@/views/mandatory/MandatoryExam.vue"),
      },
      {
        path: "/blogs",
        name: "blogs",
        component: () => import("@/views/blogs/Blogs.vue"),
      },
      {
        path: "/blogs/:id",
        name: "blog-detail",
        component: () => import("@/views/blogs/BlogDetail.vue"),
      },
      {
        path: "/event",
        name: "event",
        component: () => import("@/views/event/Event.vue"),
      },
      {
        path: "/event-list",
        name: "event-list",
        component: () => import("@/views/event/EventList.vue"),
      },
      {
        path: "/event/:id",
        name: "event-detail",
        component: () => import("@/views/event/EventDetail.vue"),
      },
      {
        path: "/qaboard",
        name: "qaboard",
        component: () => import("@/views/qaboard/Qaboard.vue"),
      },
      {
        path: "/qaboard-create",
        name: "qaboard-create",
        component: () => import("@/views/qaboard/QaboardManage.vue"),
      },
      {
        path: "/qaboard/:id",
        name: "qaboard-detail",
        component: () => import("@/views/qaboard/QaboardDetail.vue"),
      },
      {
        path: "/contactus",
        name: "contactus",
        component: () => import("@/views/contactus/Contactus.vue"),
      },
      {
        path: "/help",
        name: "help",
        component: () => import("@/views/help/Help.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile.vue"),
      },
      {
        path: "/authenticate/:examRoomCode",
        name: "verify",
        component: () => import("@/views/Verify.vue"),
      },
      {
        path: "/exam",
        name: "exam",
        component: () => import("@/views/exam/Exam.vue"),
      },
      {
        path: "/exam/:id",
        name: "exam-detail",
        component: () => import("@/views/exam/ExamDetail.vue"),
      },
      {
        path: "/exam-learn/:id",
        name: "exam-learn",
        component: () => import("@/views/exam/ExamLearn.vue"),
      },
      {
        path: "/exam-test/:sbId/:exId/:exrId",
        name: "exam-test",
        component: () => import("@/views/exam/ExamTest.vue"),
      },
      {
        path: "/exam-general-detail/:id",
        name: "exam-general-detail",
        component: () => import("@/views/exam-general/ExamDetail.vue"),
      },
      {
        path: "/exam-general-learn/:id",
        name: "exam-general-learn",
        component: () => import("@/views/exam-general/ExamLearn.vue"),
      },
      {
        path: "/exam-general",
        name: "exam-general",
        component: () => import("@/views/exam-general/ExamList.vue"),
      },
      {
        path: "/exam-history",
        name: "exam-history",
        component: () => import("@/views/exam-general/History.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Terms.vue"),
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/PrivacyPolicy.vue"),
  },
  {
    path: "/cookie",
    name: "cookie",
    component: () => import("@/views/CookiePolicy.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || { top: 0, left: 0 };
};
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior,
});

router.beforeEach(async (to, from, next) => {
  const getGuestToken = await axios.get(
    process.env.VUE_APP_API_URL + "/getGuestToken"
  );
  // console.log(getGuestToken.data.data);
  localStorage.setItem("tokenGuest", getGuestToken.data.data);
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  setTimeout(() => {
    store.dispatch(Actions.VERIFY_AUTH);
  }, 1000);
  // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
  return next();
});

export default router;
